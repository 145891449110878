import { IButtonStyles } from "@fluentui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NameIcons } from "../../../../../assets/icons";
import { RoleType } from "../../../../../common/enum";

import { PopUpCodes } from "../../../../../common/enum/PopUpCodes";
import { IActuationModal, IRequestExpedient } from "../../../../../models";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { setEditRequestActuationPopUp, updateActuationFormProperty } from "../../../../../redux/reducers/request/edit/form/actuation";
import {
    IAssignInspector,
    IUnassignInspector,
    setAssignInspectorFormData,
    setUnassignInspectorFormData,
} from "../../../../../redux/reducers/request/list/form/assignInspector";
import { CustomIcons } from "../../../../common/customIcons/customIcons";
import { isShowAddActuation, isShowAddMultipleIcon } from "../../validation/inspections";
import { RequestListExpedientsInspectorsFormControler } from "../utils/inspector/controller/assign";
import { RequestListExpedientsInspectorsUnassignAlertControler } from "../utils/inspector/controller/unassign";
import { ActuationFormController } from "./controllers/actuation";
import { RequestListActuationListController } from "./controllers/actuationList";
import { useParams } from "react-router";

interface IRequestExpedientProps {
    requestExpedient?: IRequestExpedient[];
    requestId: number;
    styles: IRequestStyles;
}

interface IRequestStyles {
    organismCode: string;
    expedient: string;
    sort: string;
    buttonIcon: any;
    icon: string;
    rowContainer: string;
    iconSort: string;
    viewMore: string;
    line: string;
    buttonRed: string;
    buttonGreen: string;
    buttonBlue: string;
    buttonOrange: string;
    buttonTrash: string;
    inspectorName: string;
    certificateNumber: string;
}

export const ExpedientsColumnComponent: React.FC<IRequestExpedientProps> = ({ requestExpedient, requestId, styles }): JSX.Element => {
    const { t } = useTranslation("common", { keyPrefix: "LIST.EXPEDIENTS_DESCRIPTIONS" });
    const { impersonatedOrganismId } = useParams();

    const [listActuationModal, setListActuationModal] = useState<IActuationModal>({ visible: false });

    const [showFormActuationModal, setShowFormActuationModal] = useState<boolean>(false);
    const [showDeleteActuationModal, setShowDeleteActuationModal] = useState<boolean>(false);

    const [showAssignInspector, setShowAssignInspector] = useState<boolean>(false);
    const [showUnassignInspector, setShowUnassignInspector] = useState<boolean>(false);

    const [inspectorId, setInspectorId] = useState<string>("");

    const { roles, email, organismId } = useAppSelector((store) => store.userData.profile);

    const isOrganismInspector = roles.some((rol) => rol.id === RoleType.ORGANISM_INSPECTOR);
    const isOrganismAdmin = roles.some((rol) => rol.id === RoleType.ORGANISM_ADMIN);
    const isOrganismTechnician = roles.some((rol) => rol.id === RoleType.ORGANISM_TECHNICIAN);
    const isLeadership = roles.some((rol) => rol.id === RoleType.LEADERSHIP);
    const isMapaDirector = roles.some((rol) => rol.id === RoleType.MAPA_DIRECTOR);
    const isImpersonatedOrganism = impersonatedOrganismId ? true : false;


    const dispatch = useAppDispatch();

    const showAssignInspectoPopUp = (expedient: IRequestExpedient) => {
        if (expedient.organismId && expedient.certificateNumber && expedient.inspectionId) {
            const data: IAssignInspector = {
                expedientNumber: expedient.certificateNumber,
                organismId: expedient.organismId,
                requestId: requestId,
                inspectionId: expedient.inspectionId,
                inspectorId: isOrganismInspector ? email : expedient.inspectorId ?? "",
            };

            dispatch(setAssignInspectorFormData(data));
        }
        setInspectorId(expedient.inspectorId ?? "");
        setShowAssignInspector(true);
    };

    const showUnassignInspectorPopup = (expedient: IRequestExpedient) => {
        if (expedient.certificateNumber && expedient.inspectionId) {
            const data: IUnassignInspector = {
                expedientNumber: expedient.certificateNumber,
                requestId: requestId,
                inspectionId: expedient.inspectionId,
                inspector: expedient.inspectorName,
            };
            dispatch(setUnassignInspectorFormData(data));
            setShowUnassignInspector(true);
        }
    };

    const iconButtonStyles: Partial<IButtonStyles> = {
        root: styles.buttonIcon,
        icon: styles.icon,
    };

    return (
        <>
            {requestExpedient?.length ? (
                <div className={styles.rowContainer}>
                    {requestExpedient?.map((expedient: IRequestExpedient, index: number) => {
                        const showAssignInspectorIcon =
                            expedient.organismId === organismId &&
                            expedient.expedientId &&
                            (isOrganismTechnician ||
                                isOrganismAdmin ||
                                isOrganismInspector ||
                                isLeadership ||
                                isMapaDirector ||
                                isImpersonatedOrganism);            

                        return (
                            <div className={styles.line}>
                                <div style={{ minWidth: 17.5 }}>
                                    {index === 0 && isShowAddMultipleIcon(requestExpedient, []) && (
                                        <CustomIcons
                                            className={styles.buttonRed}
                                            isButton={true}
                                            iconName={NameIcons.ADD}
                                            iconButtonStyles={iconButtonStyles}
                                            onClick={() => {
                                                setShowFormActuationModal(true);
                                                dispatch(setEditRequestActuationPopUp(PopUpCodes.CREATE));
                                                dispatch(updateActuationFormProperty({ name: "isMultiple", value: true }));
                                            }}
                                            size={13}
                                            tooltip={t("ADD_MULTIPLE")}
                                            margin={"0px"}
                                        />
                                    )}
                                </div>
                                <div
                                    className={styles.expedient}
                                    key={index}
                                >
                                    {expedient.certificateTypeCode ? (
                                        <div className="certificateTypeCode">{expedient.certificateTypeCode}</div>
                                    ) : null}
                                    {expedient.certificateNumber && (
                                        <div
                                            title={expedient.certificateNumber}
                                            className={styles.certificateNumber}
                                            style={{ minWidth: `${expedient.certificateNumber.length * 6.5}px` }}
                                        >
                                            {expedient.certificateNumber}
                                        </div>
                                    )}
                                    {expedient.inspectorName ? (
                                        <div
                                            className={styles.inspectorName}
                                            title={expedient.inspectorName}
                                        >
                                            - {expedient.inspectorName}
                                        </div>
                                    ) : null}{" "}
                                    {!expedient.lastActuationId && isShowAddActuation(expedient.organismId) && (
                                        <CustomIcons
                                            className={styles.buttonGreen}
                                            isButton={true}
                                            iconName={NameIcons.ADD}
                                            iconButtonStyles={iconButtonStyles}
                                            onClick={() => {
                                                setShowFormActuationModal(true);
                                                dispatch(setEditRequestActuationPopUp(PopUpCodes.CREATE));
                                                dispatch(updateActuationFormProperty({ name: "inspectionId", value: expedient.inspectionId }));
                                            }}
                                            size={13}
                                            tooltip={t("ADD_ACTUATION")}
                                            margin={"0px"}
                                        />
                                    )}
                                    {expedient.lastActuationId && isShowAddActuation(expedient.organismId) && (
                                        <CustomIcons
                                            className={styles.buttonBlue}
                                            isButton={true}
                                            iconName={NameIcons.ADD}
                                            iconButtonStyles={iconButtonStyles}
                                            onClick={() => setListActuationModal({ visible: true, inspectionId: expedient.inspectionId })}
                                            size={13}
                                            tooltip={t("VIEW_LIST")}
                                            margin={"0px"}
                                        />
                                    )}
                                    {showAssignInspectorIcon && (
                                        <CustomIcons
                                            className={styles.buttonOrange}
                                            isButton={true}
                                            iconName={NameIcons.ADD}
                                            iconButtonStyles={iconButtonStyles}
                                            onClick={() => showAssignInspectoPopUp(expedient)}
                                            size={13}
                                            tooltip={!expedient.inspectorId ? t("ADD_INSPECTOR") : t("EDIT_INSPECTOR")}
                                            margin={"0px"}
                                        />
                                    )}
                                    {expedient.inspectorId && expedient.organismId === organismId && (
                                        <CustomIcons
                                            isButton={true}
                                            iconName={NameIcons.REMOVE}
                                            iconButtonStyles={iconButtonStyles}
                                            onClick={() => showUnassignInspectorPopup(expedient)}
                                            size={15}
                                            tooltip={t("DELETE_INSPECTOR")}
                                            margin={"0px"}
                                        />
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
            ) : null}

            <ActuationFormController
                requestId={requestId}
                disabledOrganismSelector={true}
                delay={true}
                showFormActuationModal={showFormActuationModal}
                onDismissFormActuationModal={setShowFormActuationModal}
                showDeleteActuationModal={showDeleteActuationModal}
                onDismissDeleteActuationModal={setShowDeleteActuationModal}
            />

            <RequestListActuationListController
                requestId={requestId}
                infoActuation={listActuationModal}
                setInfoActuation={setListActuationModal}
            />

            <RequestListExpedientsInspectorsFormControler
                showAddInspectorToIncidence={showAssignInspector}
                setShowAddInspectorToIncidence={setShowAssignInspector}
                inspectorId={inspectorId}
            />

            <RequestListExpedientsInspectorsUnassignAlertControler
                showInspectorUnassign={showUnassignInspector}
                setShowInspectorUnassign={setShowUnassignInspector}
            />
        </>
    );
};
