import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { FormatDate } from "../../../../../../../common/enum/dateTime/FormatDate";
import { IConfigEditRequest } from "../../../../../../../models/requests/IConfigEditRequest";
import { IBillingdata, IConcept, IConceptFormTariff } from "../../../../../../../models/requests/IEditRequestBilling";
import { convertToLocal } from "../../../../../../../utils/dateUtils";
import { LabelText } from "../../../../../../common/labels/labelText/labelText";
import { InvoiceStyle } from "./invoiceStyle.jss";
import { ColumnType, CustomTableList } from "../../../../../../common/tableCustom/tableCustom";
import { CustomTableActions } from "../../../../../../common/tableCustom/actions/actions";
import { Separator } from "../../../../../../common/separator/separator";
import { widths } from "../../../../../../../styles/romeu/widths";
import { PopUpConfirmation } from "../../../../../../common/popup/popUpConfirmation/popUpConfirmation";
import {
    deleteRequestInvoiceConcepts,
    getRequestInvoiceConceptDataForm,
    putSynchronizedRequestInvoiceConceptsAction,
} from "../../../../../../../redux/actions/request/edit/action/requestEdit";
import { useAppDispatch, useAppSelector } from "../../../../../../../redux/hooks";
import { editRequestId } from "../../../../../../../redux/selectors/request/request";
import { CustomIcons } from "../../../../../../common/customIcons/customIcons";
import { NameIcons } from "../../../../../../../assets/icons";
import {
    resetEditRequestInvoiceConceptFormDataState,
    setEditRequestInvoiceConceptFormPopUp,
    updateEditRequestInvoiceConceptProperty,
} from "../../../../../../../redux/reducers/request/edit/form/concept";
import { compileNameOfProperty } from "../../../../../../../utils";
import { DefaultValues } from "../../../../../../../common/enum/DefaultValues";
import { PopUpCodes } from "../../../../../../../common/enum/PopUpCodes";
import { PopUpConfirmationType } from "../../../../../../../common/enum/PopUpConfirmationType";
import { BooleanValue } from "../../../../../../common/booleanValue/booleanValue";

interface IEditRequestInvoiceItemProps {
    billing: IBillingdata;
    schema: IConfigEditRequest;
}

export const EditRequestInvoiceItem: React.FC<IEditRequestInvoiceItemProps> = ({ billing, schema }) => {
    const theme = useTheme();
    const style = InvoiceStyle({ theme });
    const dispatch = useAppDispatch();
    const requestId = useAppSelector(editRequestId);

    const [dropDown, setDropDown] = useState<boolean>(!billing.invoiceNumber);
    const { showPopUp, form, loading } = useAppSelector((state) => state.editRequestInvoiceConceptForm);

    const iconDrop = dropDown ? "ChevronUpSmall" : "ChevronDownSmall";

    const { t } = useTranslation("common", { keyPrefix: "EDIT_REQUEST.FIELDS" });

    const columns: ColumnType<any, keyof IConcept>[] = [
        { key: "tariffDescription", title: t("INVOICE_CONCEPT") },
        { key: "quantity", title: t("INVOICE_UNITS") },
        { key: "unitPrice", title: t("INVOICE_IMPORT") },
        { key: "amount", title: t("INVOICE_AMOUNT") },
        { key: "invoiced", title: t("INVOICED") },
        { key: "synchronized", title: t("INVOICE_CONCEPT_SYNCHRONIZED") },
        { key: "actions", title: "" },
    ];

    const onAcceptDeleteInvoiceConcept = () => {
        if (form.id && form.id > DefaultValues.DEFAULT_ID) {
            dispatch(deleteRequestInvoiceConcepts(requestId, form.id)).then((response: any) => {
                response && onCancelDeleteInvoiceConcept();
            });
        }
    };
    const onCancelDeleteInvoiceConcept = () => {
        dispatch(resetEditRequestInvoiceConceptFormDataState());
    };

    const actionsConcepts = (item: IConcept) => {
        if (item.invoiced) {
            return null;
        }

        return (
            <CustomTableActions
                children={
                    <div>
                        {schema.invoiceConcept_synchronize.visible && (
                            <>
                                <CustomIcons
                                    tooltip={t("SYNC_UP_CONCEPT")}
                                    isButton={true}
                                    iconName={"SyncOccurence"}
                                    className={style.reloadIcon}
                                    onClick={() => {
                                        dispatch(putSynchronizedRequestInvoiceConceptsAction(requestId, item.id));
                                    }}
                                />
                                <Separator size="auto" />
                            </>
                        )}
                        <CustomIcons
                            isButton={true}
                            iconName={NameIcons.EDIT}
                            onClick={() => {
                                dispatch(setEditRequestInvoiceConceptFormPopUp(PopUpCodes.UPDATE));
                                dispatch(getRequestInvoiceConceptDataForm(requestId, item.id));
                            }}
                        ></CustomIcons>

                        <Separator size="auto"></Separator>

                        <CustomIcons
                            isButton={true}
                            iconName={NameIcons.REMOVE}
                            onClick={() => {
                                dispatch(setEditRequestInvoiceConceptFormPopUp(PopUpCodes.DELETE));
                                dispatch(
                                    updateEditRequestInvoiceConceptProperty({
                                        name: compileNameOfProperty<IConceptFormTariff>("id"),
                                        value: item.id,
                                    }),
                                );
                            }}
                        ></CustomIcons>
                    </div>
                }
            />
        );
    };

    const newConceptList = billing.invoiceConcepts.map((item) => {
        return {
            ...item,
            invoiced: <BooleanValue value={item.invoiced}></BooleanValue>,
            synchronized: <BooleanValue value={item.synchronized}></BooleanValue>,
            amount: `${item.amount}${DefaultValues.EURO}`,
            unitPrice: `${String(item.unitPrice)}${DefaultValues.EURO}`,
            actions: schema.invoiceConcept_create.visible ? actionsConcepts(item) : null,
        };
    });

    return (
        <div className={style.generalContainer}>
            <div className={style.headerContainer}>
                <div className={style.headerContainerData}>
                    <div className={style.labelFactura}>
                        <LabelText
                            name={t("INVOICE")}
                            value={billing.invoiceNumber ? billing.invoiceNumber : t("NOT_INVOICE")}
                        />
                    </div>
                    <div className={style.labelFactura}>
                        <LabelText
                            name={t("INVOICE_DATE")}
                            value={billing.invoiceDate ? convertToLocal(billing.invoiceDate.toString(), FormatDate.DATE) : DefaultValues.EMPTY}
                        />
                    </div>
                </div>

                <div className={style.dropDown}>
                    <CustomIcons
                        iconName={iconDrop}
                        size={"1.2rem"}
                        onClick={() => setDropDown(!dropDown)}
                    ></CustomIcons>
                </div>
            </div>

            {dropDown && (
                <CustomTableList
                    data={newConceptList}
                    columns={columns}
                    loading={false}
                />
            )}

            <PopUpConfirmation
                showPopUp={showPopUp === PopUpCodes.DELETE}
                onCancel={onCancelDeleteInvoiceConcept}
                onAccept={onAcceptDeleteInvoiceConcept}
                type={PopUpConfirmationType.DELETE}
                width={widths.confirmPopUp.remove}
                height={"auto"}
                loading={loading}
            />
        </div>
    );
};
