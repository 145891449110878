import { DetailsRow, IDetailsListProps, IDetailsRowStyles } from "@fluentui/react";
import { ReactChild, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router";
import { SkeletonSizes, componentsMediaQueries } from "../../../../common/constants";
import { routeFeatures } from "../../../../common/constants/routeFeatures";

import { OrganismTypes, RoleType } from "../../../../common/enum";
import { PopUpConfirmationType } from "../../../../common/enum/PopUpConfirmationType";
import { ListCommandType } from "../../../../common/enum/ViewTypes";
import { ActuationTypes } from "../../../../common/enum/request/ActuationTypes";
import { ViewType } from "../../../../models";
import { IPagination } from "../../../../models/common/IPagination";
import { IUserRoleView, IView } from "../../../../models/requests/IUserRoleView";
import { fetchOrganisms, fetchTurns } from "../../../../redux/actions/catalog/catalog";
import { fetchRequestExportAction } from "../../../../redux/actions/request/export/exportList";
import { fetchRequestListAction, fetchSilentRequestListAction, suplantOrganismUser } from "../../../../redux/actions/request/list/requestList";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { resetConcurrency } from "../../../../redux/reducers/request/edit/editRequest";
import { setShowPopUpFilter } from "../../../../redux/reducers/request/list/filter";
import { getUserClaimsLoaded } from "../../../../redux/selectors/user/claims/claims";
import i18n from "../../../../services/i18n";
import { ButtonPrimary } from "../../../common/buttons/buttons";
import { CustomDetailsList } from "../../../common/customDetailsList/customDetailsList";
import { ExportDataIcon } from "../../../common/exportedData/exportData";
import { NavHeaderComponent } from "../../../common/navHeader/navHeader";
import { PopUp } from "../../../common/popup/popUp";
import { PopUpConfirmation } from "../../../common/popup/popUpConfirmation/popUpConfirmation";
import { Skeleton } from "../../../common/skeleton/skeleton";
import { RequestListFilterPivotContainer } from "../../filter/container/pivot/pivot";
import { RequestListFilterPopUpContainer } from "../../filter/container/popUp/popUp";
import { CreateRequestContainer } from "../../form/create/container";
import { RequestCard } from "../card/requestCard";
import { RequestColumns } from "./columns/columns";
import { RequestListStyle } from "./listStyle.jss";

export const RequestList = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common");
    const theme = useTheme();
    const styles = RequestListStyle({ theme });
    const isMobile = useMediaQuery({ query: componentsMediaQueries.MOBILE });

    const [isCreateRequestVisible, setCreateRequestVisible] = useState<boolean>(false);

    const { impersonatedOrganismId } = useParams();

    const {
        data,
        list,
        pagination,
        loading,
        showAllRequest,
        concurrency,
        isViewLoaded,
        viewPermission,
        role,
        roles,
        turns,
        isUserClaimsLoaded,
        persistScrollOnDetailsList,
    } = useAppSelector((state) => ({
        data: state.catalog.organisms.data,
        list: state.requestlist.list,
        pagination: state.requestlist.pagination,
        loading: state.requestlist.loading,
        showAllRequest: state.requestlist.showAllRequest,
        concurrency: state.editRequest.concurrency,
        isViewLoaded: state.userRoleViews.isViewLoaded,
        viewPermission: state.userRoleViews.viewPermission,
        role: state.role.roleData,
        roles: state.userData.profile.roles,
        turns: state.catalog.turns.data,
        isUserClaimsLoaded: getUserClaimsLoaded(state),
        persistScrollOnDetailsList: state.globalConfig.persistScrollOnDetailsList,
    }));

    const viewRequestPermision: IUserRoleView | undefined = useMemo(() => {
        if (Array.isArray(viewPermission)) {
            return viewPermission.find((feature) => feature.id === routeFeatures.REQUEST);
        }
        return viewPermission[0];
    }, [viewPermission]);

    const viewCommands = useMemo(() => {
        return viewRequestPermision?.views.find((view) => view.type === ViewType.COMMANDS) as IView;
    }, [viewRequestPermision?.views]);

    const showCreateRequest = useMemo(() => {
        return (viewCommands && viewCommands.fields?.find((field) => field.name === ListCommandType.CREATE)) !== undefined;
    }, [viewCommands]);

    const showExport = useMemo(() => {
        return (viewCommands && viewCommands.fields?.find((field) => field.name === ListCommandType.EXPORT)) !== undefined;
    }, [viewCommands]);

    const showChangeDate = useMemo(() => {
        return (viewCommands && viewCommands.fields?.find((field) => field.name === ListCommandType.CHANGE_DATE)) !== undefined;
    }, [viewCommands]);

    const addMultipleActuation = useMemo(() => {
        return (viewCommands && viewCommands.fields?.find((field) => field.name === ListCommandType.ADD_MULTIPLE_ACTUATION)) !== undefined;
    }, [viewCommands]);

    const showEditRequestComands = useMemo(() => {
        return (viewCommands && viewCommands.fields?.find((field) => field.name === ListCommandType.UPDATE)) !== undefined;
    }, [viewCommands]);

    const extendedHeight =
        showChangeDate ||
        role.id === RoleType.AGENT_ADMIN ||
        role.id === RoleType.AGENT_USER ||
        role.id === RoleType.STAND ||
        role.id === RoleType.LEADERSHIP ||
        role.id === RoleType.ORGANISM_ADMIN ||
        role.id === RoleType.ORGANISM_INSPECTOR ||
        role.id === RoleType.MAPA_DIRECTOR ||
        role.id === RoleType.ORGANISM_TECHNICIAN ||
        role.id === RoleType.CUSTOMER_ADMIN;

    const detailListHeight = extendedHeight ? "calc(100vh - 300px)" : "calc(100vh - 260px)";

    const onExportCSV = useCallback(() => {
        dispatch(fetchRequestExportAction());
    }, [dispatch]);

    const onClosePopUp = useCallback(() => {
        setCreateRequestVisible(false);
    }, []);

    const onCreateRequestClick = useCallback(() => {
        setCreateRequestVisible(true);
    }, []);

    const onPageUp = useCallback(
        (skip: number) => {
            const currentPage: number = skip + 1;
            if (currentPage <= pagination.totalPages) {
                dispatch(fetchRequestListAction(currentPage));
            }
        },
        [pagination.totalPages],
    );

    const onFirstPage = useCallback((skip: number) => {
        if (skip !== 1) {
            dispatch(fetchRequestListAction(1));
        }
    }, []);

    const onPageDown = useCallback((skip: number) => {
        const currentPage: number = skip - 1;
        if (currentPage >= 1) {
            dispatch(fetchRequestListAction(currentPage));
        }
    }, []);

    const onLastPage = useCallback(
        (skip: number) => {
            if (skip !== pagination.totalPages) {
                dispatch(fetchRequestListAction(pagination.totalPages));
            }
        },
        [pagination.totalPages],
    );

    const paginationProps: IPagination = {
        currentPage: pagination.currentPage,
        totalPages: pagination.totalPages,
        totalItems: pagination.totalItems,
        firstItemNumber: pagination.firstItemNumber,
        lastItemNumber: pagination.lastItemNumber,
        footerTitle: t("LIST.PAGINATION_FOOTER", {
            firstItemNumber: pagination.firstItemNumber,
            lastItemNumber: pagination.lastItemNumber,
            totalItems: pagination.totalItems,
        }),
        onFirstPage,
        onLastPage,
        onPageDown,
        onPageUp,
    };

    const exportButton: ReactChild = <>{showExport && <ExportDataIcon onExportCSV={onExportCSV} />}</>;

    const actionsButtons: ReactChild = (
        <>
            <PopUp
                children={<CreateRequestContainer onAbandon={onClosePopUp} />}
                state={isCreateRequestVisible}
                isBlocking={true}
                skipHeader={true}
                skipPadding={true}
            />
            {showCreateRequest && (
                <ButtonPrimary
                    title={t("LIST.BUTTON.ADD")}
                    handleClick={onCreateRequestClick}
                />
            )}
        </>
    );

    const onReloadList = useCallback(() => {
        dispatch(fetchSilentRequestListAction());
        dispatch(resetConcurrency());
    }, [dispatch]);

    const onRenderRow: IDetailsListProps["onRenderRow"] = (props) => {
        const customStyles: Partial<IDetailsRowStyles> = {};
        if (props) {
            if (
                props.item.lastActuation !== null &&
                (role.id === RoleType.ORGANISM_ADMIN || role.id === RoleType.ORGANISM_TECHNICIAN || role.id === RoleType.ORGANISM_INSPECTOR) &&
                props.item.lastActuation.actuationTypeCode === ActuationTypes.PENDING
            ) {
                customStyles.root = { color: "red !important" };
            } else {
                customStyles.root = { color: "black !important" };
            }

            return (
                <DetailsRow
                    {...props}
                    styles={customStyles}
                />
            );
        }
        return null;
    };

    const viewRequestPermission: IUserRoleView | undefined = useMemo(
        () => viewPermission?.find((feature) => feature.id === routeFeatures.REQUEST),
        [viewPermission],
    );

    const isCustomer = useMemo(() => {
        return roles.find((item) => item.id === RoleType.CUSTOMER_ADMIN || item.id === RoleType.CUSTOMER_USER) ? true : false;
    }, [roles]);

    const viewConfig = useMemo(() => {
        return viewRequestPermission?.views.find((view) => view.type === ViewType.LIST) as IView;
    }, [viewRequestPermission?.views]);

    const isVisibleRedRow = useMemo(() => {
        let view = viewConfig?.fields.find((item) => item.name === "redRow");
        return view ? true : false;
    }, [viewConfig]);

    const isVisibleGreenRow = useMemo(() => {
        let view = viewConfig?.fields.find((item) => item.name === "greenRow");
        return view ? true : false;
    }, [viewConfig]);

    const renderColumns = () => {
        return RequestColumns(routeFeatures.REQUEST, isCustomer, isVisibleRedRow, isVisibleGreenRow);
    };

    const listResponsive = useMemo(() => {
        return !isMobile ? (
            <CustomDetailsList
                data={list}
                pagination={paginationProps}
                height={detailListHeight}
                renderColumns={renderColumns}
                onRenderRow={onRenderRow}
                persistScroll={persistScrollOnDetailsList}
            />
        ) : (
            <div className={styles.cardList}>
                {list.map((value) => {
                    return (
                        <RequestCard
                            key={value.id}
                            data={value}
                            forceShow={showAllRequest}
                            showEdit={showEditRequestComands}
                        />
                    );
                })}
            </div>
        );
    }, [list, paginationProps, detailListHeight, isMobile, persistScrollOnDetailsList, showAllRequest, showEditRequestComands]);

    const titleListRequest = useMemo(() => {
        switch (impersonatedOrganismId) {
            case OrganismTypes.ANIMAL_HEALTH:
                return t("LIST.ANIMAL_HEALTH");
            case OrganismTypes.FOREIGN_HEALTH:
                return t("LIST.FOREIGN_HEALTH");
            case OrganismTypes.PLANT_HEALTH:
                return t("LIST.VEGETAL_HEALTH");
            case OrganismTypes.PHARMACY:
                return t("LIST.PHARMACY");
            case OrganismTypes.CUSTOMS:
                return t("LIST.CUSTOMS");
            case OrganismTypes.FISHING:
                return t("LIST.FISHING");
            case OrganismTypes.SOIVRE:
                return t("LIST.SOIVRE");
            case OrganismTypes.OTHERS:
                return t("LIST.OTHERS");
            case OrganismTypes.TAX_CANARY:
                return t("LIST.TAX_CANARY");
            case OrganismTypes.BORDER_INSPECTION_BODY:
                return t("LIST.BORDER_INSPECTION_BODY");
            default:
                return "";
        }
    }, [impersonatedOrganismId]);

    useEffect(() => {
        data.length > 0 && dispatch(suplantOrganismUser(pagination.currentPage, impersonatedOrganismId, showEditRequestComands));
    }, [impersonatedOrganismId, data]);

    useEffect(() => {
        !data.length && dispatch(fetchOrganisms());
    }, [data]);

    useEffect(() => {
        dispatch(setShowPopUpFilter(undefined as any));
    }, [isMobile]);

    useEffect(() => {
        turns.length === 0 && dispatch(fetchTurns());
    }, [dispatch, turns]);

    return (
        <>
            <NavHeaderComponent
                title={`${t("LIST.TITLE")} ${titleListRequest}`}
                action={!isMobile ? actionsButtons : undefined}
                endAction={!isMobile ? exportButton : undefined}
            />

            {isMobile ? (
                <RequestListFilterPopUpContainer showCreateRequest={showCreateRequest} />
            ) : (
                <RequestListFilterPivotContainer
                    extendedHeight={extendedHeight}
                    showChangeDate={showChangeDate}
                    addMultipleActuation={addMultipleActuation}
                />
            )}

            <div className={styles.requestContainer}>
                {loading || !isUserClaimsLoaded || !isViewLoaded ? <Skeleton rows={SkeletonSizes.REQUEST} /> : listResponsive}
            </div>

            <PopUpConfirmation
                showPopUp={concurrency.isError}
                onCancel={() => null}
                onAccept={onReloadList}
                type={PopUpConfirmationType.WARNING}
                width={"30vw"}
                height={"auto"}
                message={concurrency.message}
                hiddeCancelButon={true}
                hiddeCloseButon={true}
                buttonAcceptTitle={i18n.t("common:COMMON.RELOAD")}
            />
        </>
    );
};
