import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../../../common/constants";

export const GeneralInformationEditContainerStyle = createUseStyles((theme: any) => ({
    pivotContainer: {
        display: "flex",
    },
    container: {
        height: theme.heights.requestEditContent,
        [mediaQueries.TABLET]: {
            height: theme.heights.requestEditMobileContent,
        },

        paddingRight: 24,
        paddingLeft: 24,
    },
    sectionContainer: {
        width: "100%",
        flexFlow: "row wrap",
        display: "flex",
        columnGap: 24,
        rowGap: theme.gaps.sizes.xl,
    },
    sealsSectionContainer: {
        width: "100%",
        flexFlow: "row wrap",
        display: "flex",
        columnGap: 10,
        rowGap: theme.gaps.sizes.sm,
    },
    batchesSectionContainer: {
        width: "100%",
        flexFlow: "row wrap",
        display: "flex",
        columnGap: 10,
        rowGap: theme.gaps.sizes.sm,
    },
    section: {
        display: "flex",
        [mediaQueries.MOBILE]: {
            flexDirection: "column",
            columnGap: 15,
        },
        gap: 10,
    },
    title: {
        width: "100%",
        display: "flex",
        gap: theme.gaps.buttons.default,
        alignItems: "center",
        color: theme.colors.site.color,
    },
    borderRight: {
        borderRight: "solid",
        height: "11rem",
        display: "flex",
        flexDirection: "column",
        gap: 10,
        [mediaQueries.MOBILE]: {
            paddingBottom: 10,
            borderRight: "none",
            borderBottom: "solid",
            height: "auto",
        },
        paddingRight: 0,
    },
    dateContainer: {
        display: "flex",
        flexDirection: "column",
        gap: 10,
        marginLeft: 20,
    },
    validate: {
        width: "100%",
        flexFlow: "row wrap",
        display: "flex",
        columnGap: 10,
        rowGap: theme.gaps.sizes.sm,
    },
    gridContainer: {
        display: "flex",
        flexDirection: "column",
        gap: 30,
        [mediaQueries.MOBILE]: {
            gap: 10,
        },
    },
    titleSeal: {
        display: "flex",
        gap: theme.gaps.sizes.base,
        alignItems: "center",
        flexWrap: "wrap",
    },
    titleBatch: {
        display: "flex",
        gap: theme.gaps.sizes.base,
        alignItems: "center",
        flexWrap: "wrap",
    },
    row: {
        display: "flex",
        marginBottom: 10,
        [mediaQueries.MOBILE]: {
            flexDirection: "column",
            gap: 20,
        },
    },
    parkingValidation: {
        display: "flex",
        gap: 20,
    },
    parkingValidationAction : {
        display:"flex"
    }
}));
