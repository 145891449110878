export const routeUrls = {
    HOME: "/",
    SIGNIN: "/signin",
    SIGNIN_CALLBACK: "/signing-callback",
    SILENT_RENEW: "/silent-renew.html",
    SIGNUP: "/signup",
    SIGNUP_CONFIRMATION: "/signup-confirmation/:id",
    USER_REGISTRATION: "/signup-user/:id",
    FORBIDDEN: "/forbidden",
    INTERNAL_SERVER_ERROR: "/something-went-wrong",
    ERROR: "/error",
    REQUEST: "/request",
    EDIT_REQUEST: "/request/:id",
    CALENDAR: "/calendar",
    APPOINTMENTS: "/appointments",
    QUEUES: "/queue",
    PROFILE: "/profile",
    DASHBOARD: "/dashboard",
    INDICATORS_REQUESTS: "/indicators-requests",
    INCIDENCES: "/incidences",
    INVOICES: "/invoices",
    CONFIGURATION: "/configuration",
    RESOURCES: "/resources",
    CONNECTIONS: "/connections",
    USERS: "/users",
    INCIDENCES_TYPES: "/incidences-types",
    PERMISSIONS: "/permissions",
    PANELS: "/panels",
    ENTITY: "/entity",
    REPRESENTATION: "/management/representative",
    DRAFT: "/draft",
    INVOICING_SUMARY_BY_CONCEPT: "/invoicing/summary-by-concept",
    INVOICING_UNINVOICED: "/invoicing/uninvoiced",
    INVOICING_NON_SYNCHRONIZED: "/invoicing/non-synchronized",
    MANAGEMENT_USER: "/management/user",
    MANAGEMENT_NOTIFICATIONS: "/management/notification",
    MANAGEMENT_USER_SIGNUP: "/management/user-signup",
    MANAGEMENT_USER_SIGNUP_FORM: "/management/user-signup/:id",
    MANAGEMENT_TARIFF: "/management/tariff",
    MANAGEMENT_WORK_CALENDAR: "/management/work-calendar",
    STATISTICS_CUSTOMER: "/statistics/customer",
    STATISTICS_ORGANISM_INCIDENCE: "/statistics/organism-incidence",
    STATISTICS_OPERATOR_INCIDENCE: "/statistics/operator-incidence",
    STATISTICS_INSPECTION: "/statistics/inspection",
    STATISTICS_MONTHLY_SUMMARY: "/statistics/monthly-summary",
    STATISTICS_READER_RECORD_RATIO: "/statistics/reader-record-ratio",
    STATISTICS_DAILY_CHARGE: "/statistics/daily-charge",
    STATISTICS_REQUEST_GRAPH: "/statistics/request-graph",
    STATISTICS_REQUEST_GRAPH_ORGANIZATION: "/statistics/request-graph/:organization",
    DOCK_DISPLAY: "/management/dock-display-screen",
    DOCK_DISPLAY_ORGANIZATION: "/management/dock-display-screen/:organization",
    POSITIONING_DISPLAY: "/management/positioning-display-screen",
    POSITIONING_DISPLAY_ORGANIZATION: "/management/positioning-display-screen/:organization",
    MANAGEMENT_INCIDENCE_TYPE: "/management/incidence-type",
    MANAGEMENT_REQUEST_DATEPERMISION: "/management/request-date-permission",
    CONTROL_EXPEDIENT: "/control/expedient",
    ORGANISM_BORDER_INSPECTION_REQUEST: "/organismBorderInspection-request/:impersonatedOrganismId",
    ORGANISM_BORDER_INSPECTION_EDIT_REQUEST: "/organismBorderInspection-request/:impersonatedOrganismId/:id",
    ORGANISM_VEGETABLE_HEALTH_REQUEST: "/organismVegetableHealth-request/:impersonatedOrganismId",
    ORGANISM_VEGETABLE_HEALTH_EDIT_REQUEST: "/organismVegetableHealth-request/:impersonatedOrganismId/:id",
    ORGANISM_FOREIGN_HEALTH_REQUEST: "/organismForeignHealth-request/:impersonatedOrganismId",
    ORGANISM_FOREIGN_HEALTH_EDIT_REQUEST: "/organismForeignHealth-request/:impersonatedOrganismId/:id",
    ORGANISM_ANIMAL_HEALTH_REQUEST: "/organismAnimalHealth-request/:impersonatedOrganismId",
    ORGANISM_ANIMAL_HEALTH_EDIT_REQUEST: "/organismAnimalHealth-request/:impersonatedOrganismId/:id",
    ORGANISM_PHARMACY_REQUEST: "/organismPharmacy-request/:impersonatedOrganismId",
    ORGANISM_PHARMACY_EDIT_REQUEST: "/organismPharmacy-request/:impersonatedOrganismId/:id",
    ORGANISM_SOIVRE_REQUEST: "/organismSoivre-request/:impersonatedOrganismId",
    ORGANISM_SOIVRE_EDIT_REQUEST: "/organismSoivre-request/:impersonatedOrganismId/:id",
    ORGANISM_CUSTOMS_REQUEST: "/organismCustoms-request/:impersonatedOrganismId",
    ORGANISM_CUSTOMS_EDIT_REQUEST: "/organismCustoms-request/:impersonatedOrganismId/:id",
    ORGANISM_FISHING_REQUEST: "/organismFishing-request/:impersonatedOrganismId",
    ORGANISM_FISHING_EDIT_REQUEST: "/organismFishing-request/:impersonatedOrganismId/:id",
    ORGANISM_CANARIAN_REQUEST: "/organismCanarian-request/:impersonatedOrganismId",
    ORGANISM_CANARIAN_EDIT_REQUEST: "/organismCanarian-request/:impersonatedOrganismId/:id",
    ORGANISM_OTHERS_REQUEST: "/organismOthers-request/:impersonatedOrganismId",
    ORGANISM_OTHERS_EDIT_REQUEST: "/organismOthers-request/:impersonatedOrganismId/:id",
    MASTER: "/catalog/master",
    ORGANIZATION: "/catalog/organization",
    ASSIGNMENT: "/assignment-queue",
    MANAGEMENT_SUMMARY: "/management/active-summary-record",
    UPCOMING_APPOINTMENTS: "/upcoming-appointments",
    UPCOMING_APPOINTMENTS_SORT: "/upcoming-appointments-sort",
    CONFIG_MERCHANDISE_CATEGORY: "/catalog/config-merchandise-category",
    CONFIG_DOCK_MERCHANDISE: "/catalog/config-dock-merchandise",
    CONFIG_DOCK_BLOCK: "/catalog/config-dock-block",
    MASTER_APPOINTMENT: "/catalog/appointment",
    MASTER_APPOINTMENT_BOARD: "/catalog/appointment/board/:id",
    APPOINTMENT_BOARD: "/appointment/dashboard",
    APPOINTMENT_IMPORT: "/appointment/import",
    AUTHORIZED_TRANSPORT_UNIT_NUMBER: "/management/authorized-transport-unit-number",
    FINISHED_REQUESTS: "/catalog/inspection-results",
    EDIT_FINISHED_REQUESTS: "/catalog/inspection-results/:id",
    IMPORT_REQUESTS: "/catalog/positionings-import",
    USER_IMPORT: "/catalog/customers-import",
    VLCPORT_HISTORIC: "/catalog/vlcport-query",
    LEGAL_NOTICE: "/legal-notice",
    PRIVACY_POLICY: "/privacy-policy",
    COOKIES_POLICE: "/cookies-policy",
    SUGGESTIONS_MAILBOX: "/suggestions-mailbox",
    WHISTLEBLOWING_CHANNEL: "https://whistleblowersoftware.com/secure/romeu",
    NOT_FOUND: "/not-found",
    IMPORT_CARRIER: "/catalog/carriers-import",
    PARKING_VALIDATION_RULE: "/management/parking-validation-rule",
    MENU_SETTINGS: "/catalog/menu-settings-role",
    ORGANISM_CERTIFICATED_TYPE: "/catalog/config-organism-certificate-type",
    APPOINTMENT_HISTORIC: "/appointment/historic",
    PROCESSES: "/processes",
    QUEUEASSIGNMENT_IMPORT: "queueAssignment/import",
    REQUEST_INTEGRATIONS: "/requestIntegrations"
};
