import { createUseStyles } from "react-jss";

export const ManagementDockDisplayWaitingVehiclesStyle = createUseStyles((theme: any) => ({
    containerWaiting: {
        display: "flex",
        alignItems: "center",
        gap: theme.gaps.sizes.base,
        paddingLeft: theme.paddings.sizes.md,
        paddingBottom: theme.paddings.sizes.sm,
        borderBottom: theme.borders.table.body.border,
        width: "100%",
    },
    iconTime: {
        color: theme.colors.palette.yellow,
        width: theme.fonts.doockDisplay.text,

        stroke: theme.colors.palette.yellow,
        fontWeight: 600,

        "& .st0": {
            stroke: theme.colors.palette.orange,
            color: theme.colors.palette.orange,
        },
    },
    vehicles: {
        fontSize: theme.fonts.doockDisplay.text,
        color: theme.colors.palette.black,
        fontWeight: 600,
    },
}));
