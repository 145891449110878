import { SealTypes } from "../../common/enum/SealType";
import { IActionsTableResponse } from "../../components/common/tableCustom/tableCustom";

export interface IEditRequest {
    allowPrintInspectionCard: boolean;

    id: number;

    // Step 1. UTI
    transportUnitId?: number;
    transportUnitSizeId?: number;
    date?: Date;
    stateId?: number;
    stateSort?: number;
    turnId?: number;
    customerId: string;
    invoiceCustomerId: number | string;
    customerReference?: string;
    booking: string | null;
    customsStateId: number;
    temporaryRegime: boolean;
    containerTypeId?: number;
    transportUnitNumber: string;
    tractorNumber: string;
    packages: number | null;
    grossWeight: number | null;
    palletized: boolean;
    originSeals: ISeal[];
    seals: ISeal[];
    batches: IBatch[];
    parkingAuthorization: boolean;
    entryOn: Date;
    exitOn: Date;
    dockId?: number;
    dockAssignmentOn: Date;
    dockStartOn: Date;
    dockEndOn: Date;
    representativeId: number;
    customerEntityName: string;

    // Step 2. Información de partida
    merchandiseOriginId: number;
    humanConsumption: boolean;
    merchandiseTypeId: number;
    description: string;
    tariffHeading: string;
    merchandiseArrivalShip: string;
    summaryNumber: string;
    terminalId?: number;
    merchandiseCategoryId: number;

    // Step 3. Certificados
    fumigated: boolean;
    dryCharge: boolean;
    organicProduct: boolean;
    halalCertified: boolean;
    exporter: string;
    importer: string;
    carrierId: string;
    remarks: string;
    inspectionPresence: boolean;
    representativePresenceTypeId: number;
    finalDestinationTypeId: number;
    carrierPhone: string;
    c5Number: string;
    carrierDockAssignmentNotifiedOn: Date;

    // VUA data
    saplNumber: string;
    saplCreatedOn: Date;
    authorizedOn: Date;
    cancelledOn: Date;
    duaMrnNumber: string;
    vuaNumber: string;

    // Facturacion
    validationRemarks: string;
    invoicingRemarks: string;
    invoiced: boolean;

    showFumigatedMessage: boolean;
    originCountryCode?: string;
    showCustomerNotificationMessage?: boolean;
    appointmentTypeId?: number;
    appointmentTypeCode?: string;
    appointmentTypeDescription?: string;
    appointmentAssigned?: boolean;
    appointmentCreate?: boolean;
    appointment?: Appointment | null;
    carrierEndNotifiedOn?: string;
    appointmentTimeZoneId?: number;

    customsAuthorityArrivalOn?: Date;
    representativeArrivalOn?: Date;
    readyForInstructionOn?: Date;
    automaticParkingAuthorization: boolean;

    lastModifiedOn: Date | string;
    lastModifiedBy: string;

    hasInvoiceConcepts: boolean;
}

export interface Appointment {
    id?: number;
    timeZoneId: number;
    timeZoneCode?: string;
    timeZoneDescription?: string;
    dockId: number;
    dockCode?: string;
    dockDescription?: string;
    startTime?: Date | string;
    endTime?: Date | string;
}

export interface ISeal {
    id?: number;
    typeId: SealTypes;
    typeCode?: string;
    typeDescription?: string;
    code: string;
}

export interface OriginSealWithIndex extends ISeal {
    index: number;
}

export interface IBatch {
    id?: number;
    code: string;
}

export interface IIncidenceRequest {
    loadingInspection: boolean;
    inspections: IInspection[];
    inspection: IInspection;
}

export interface IRequestInspection {
    loading: boolean;
    inspections: IInspection[];
}
export interface IRequestInspectionForm {
    id?: number;
    organismId?: number;
    certificateTypeId?: number;
    certificateNumber: string;
    exporter: string;
    importer: string;
    year: string;
    inspectorArrivedOn?: Date | string | null;
    manually?: boolean;
    hasVua?: boolean;
    duaItemNumber?: string;
    duaMrnNumber?: string;
    vuaOrganismCode?: string;
}

export interface IInspection {
    id: number;
    organismId?: number;
    certificateTypeId?: number;
    certificateNumber: string;
    certificateNumberDisabled: boolean;
    exporter: string;
    importer: string;
    year: string;
    vuaOrganismCode: string;
    inspectorId: string;
    loadingInspection: boolean;
    documentary: boolean;
    duaItemNumber: string;
    duaMrnNumber: string;
    inspectorArrivedOn: Date | string | null;
    hasSampleStorageData: boolean;
    manually: boolean;
    remarks: string;
    hasVua: boolean;
    actuations: IActuation[];
    organismDescription?: string;
    certificateTypeDescription?: string;
    inspectorName: string;
    expedientHistories: IHistoryExpedient[];
}
export interface IHistoryExpedient {
    createdOn: string;
    inspectorName: string;
    expedientId: number;
    inspectorId: string;
}

export interface ICreateActuation {
    id?: number;
    organismId: number;
    typeId: number;
    instructions: string;
}
export interface IActuation extends IActionsTableResponse {
    id?: number;
    date?: string;
    organismId: number;
    typeId: number;
    instructions: string;
    inspectionId?: number;
    description?: string;
    loading?: boolean;
    organismDescription?: string;
    inspectorArrivedOn?: string;
    isMultiple?: boolean;
}
export interface IInspectionWithIndex {
    index: number;
    inspection: IInspection;
}

export interface IEditRequestAppointment {
    appointmentTypeId?: number;
    appointmentTypeCode?: string;
    appointmentTypeDescription?: string;
    appointmentCreate?: boolean;
    appointmentFringes?: string;
    appointmentType?: string;
}

export const emptyInspection = (): IInspection => {
    return {
        id: 0,
        certificateNumber: "",
        exporter: "",
        importer: "",
        year: new Date().getFullYear().toString(),
        loadingInspection: false,
        inspectorId: "",
        vuaOrganismCode: "",
        documentary: false,
        manually: false,
        duaItemNumber: "",
        duaMrnNumber: "",
        inspectorArrivedOn: null,
        remarks: "",
        hasVua: false,
        actuations: [],
        inspectorName: "",
        expedientHistories: [],
        hasSampleStorageData: false,
        certificateNumberDisabled: false,
    };
};

export const emptyInspections = (): IInspection[] => {
    return [];
};

export const emptyOriginSeals = (): ISeal[] => {
    return [];
};

export const emptyOriginSeal = (): ISeal => {
    return {
        code: "",
        typeId: SealTypes.NONE,
    };
};

export const emptyBatches = (): IBatch[] => {
    return [];
};

export const emptyBatch = (): IBatch => {
    return {
        code: "",
    };
};
