import { IToggleStyles, Toggle } from "@fluentui/react";
import { useMemo, useState } from "react";
import { useTheme } from "react-jss";
import { InfoCallout } from "../callout/infoCallout";
import { CustomToogleStyle } from "./toogleStyle.jss";

interface ICustomToogleProps {
    checked: boolean;
    onChage?: (event: any, checked?: boolean | undefined, name?:string) => any;
    onText?: string;
    offText?: string;
    label?: string;
    inlineLabel?: boolean;
    info?: string;
    disabled?: boolean;
    name?:string;
}

export const CustomToogle: React.FC<ICustomToogleProps> = ({ checked, onChage, onText, offText, label, inlineLabel, info, disabled, name }) => {
    const [newCheckState, setNewCheckState] = useState<boolean>(checked);

    const theme = useTheme();
    const style = CustomToogleStyle({ theme, newCheckState, disabled });

    const customStyle: IToggleStyles = useMemo(() => {
        return {
            root: style.root,
            label: style.label,
            container: style.toogleContainer,
            pill: undefined,
            text: undefined,
            thumb:  undefined,
        };
    }, [checked, disabled]);

    const onChangeCheck = (event: any, checked?: boolean | undefined) => {
        onChage && onChage(event, checked !== undefined ? checked : false, name);
        setNewCheckState(checked !== undefined ? checked : false);
    };

    return (
        <div className={style.container}>
            <Toggle
                label={label}
                checked={newCheckState}
                onText={onText}
                offText={offText}
                onChange={onChangeCheck}
                styles={customStyle}
                inlineLabel={inlineLabel}
                disabled={disabled}
            />
            {info && (
                <div className={style.calloutContainer}>
                    <InfoCallout message={info} />
                </div>
            )}
        </div>
    );
};
export const TrafficLightToogle: React.FC<ICustomToogleProps> = ({ checked, onChage, onText, offText, label, inlineLabel, info, disabled, name }) => {
    const [newCheckState, setNewCheckState] = useState<boolean>(checked);

    const theme = useTheme();
    const style = CustomToogleStyle({ theme, newCheckState, disabled });

    const customStyle: IToggleStyles = useMemo(() => {
        return {
            root: style.traficToogleroot,
            label: style.traficTooglelabel,
            container: style.traficToogleContainer,
            pill: undefined,
            text: undefined,
            thumb: style.thumb ,
        };
    }, [checked, disabled]);

    const onChangeCheck = (event: any, checked?: boolean | undefined) => {
        onChage && onChage(event, checked !== undefined ? checked : false, name);
        setNewCheckState(checked !== undefined ? checked : false);
    };

    return (
        <div className={style.container}>
            <Toggle
                label={label}
                checked={newCheckState}
                onText={onText}
                offText={offText}
                onChange={onChangeCheck}
                styles={customStyle}
                inlineLabel={inlineLabel}
                disabled={disabled}
            />
            {info && (
                <div className={style.calloutContainer}>
                    <InfoCallout message={info} />
                </div>
            )}
        </div>
    );
};


