export const routeFeatures = {
    REQUEST: "requests|request",
    DRAFT: "requests|draft",
    CALENDAR: "requests|calendar",
    MANAGEMENT_USER: "management|user",
    MANAGEMENT_TARIFF: "management|tariff",
    MANAGEMENT_WORK_CALENDAR: "management|workcalendar",
    MANAGEMET_USER_SIGNUP: "management|usersignup",
    MANAGEMENT_REPRESENTATIVE: "management|representative",
    MANAGEMENT_NOTIFICATION: "management|notification",
    MANAGEMENT_INCIDENCE_TYPE: "management|incidencetype",
    STATISTICS_CUSTOMER: "statistics|customer",
    STATISTICS_INCIDENCE_ORGANISM: "statistics|organismincidence",
    STATISTICS_INCIDENCE_OPERATOR: "statistics|operatorincidence",
    STATISTICS_INSPECTION: "statistics|inspection",
    STATISTICS_MONTHLY_SUMMARY: "statistics|monthlysummary",
    STATISTICS_DAILY_CHARGE: "statistics|dailycharge",
    STATISTICS_READER_RECORD_RATIO: "statistics|readerrecordratio",
    STATISTICS_REQUEST_GRAPH: "statistics|requestgraph",
    DOCK_DISPLAY: "management|dockdisplayscreen",
    POSITIONING_DISPLAY: "management|positioningdisplayscreen",
    MANAGEMENT_REQUEST_DATEPERMISION: "management|requestdatepermission",
    DEVELOPERTOOLS_PROCESSES: "developerTools|processes",
    CONTROL_EXPEDIENT: "control|expedient",
    REQUEST_VEGETABLE_HEALTH: "requests|vegetablehealthrequest",
    REQUEST_FOREIGNH_HEALTH: "requests|foreignhealthrequest",
    REQUEST_ANIMAL_HEALTH: "requests|animalhealthrequest",
    REQUEST_PHARMACY: "requests|pharmacyrequest",
    REQUEST_SOIVRE: "requests|soivrerequest",
    REQUEST_CUSTOMS: "requests|customsrequest",
    REQUEST_FISHING: "requests|fishingrequest",
    REQUEST_CANARIAN: "requests|canarianrequest",
    ORGANISM_REQUEST_VEGETABLE_HEALTH: "requests|vegetablehealthrequest",
    ORGANISM_REQUEST_FOREIGN_HEALTH: "requests|foreignhealthrequest",
    ORGANISM_REQUEST_ANIMAL_HEALTH: "requests|animalhealthrequest",
    REQUEST_BORDER: "requests|borderInspectionrequest",
    ASSIGNMENT: "requests|assignmentqueue",
    REQUEST_OTHERS: "requests|othersrequest",
    MASTERS: "catalog|master",
    ORGANIZATION: "catalog|organization",
    MANAGEMENT_SUMMARY: "management|activesummaryrecord",
    UPCOMING_APPOINTMENTS: "requests|upcomingappointments",
    CONFIG_MERCHANDISE_CATEGORY: "catalog|merchandisecategoryconfig",
    CONFIG_DOCK_MERCHANDISE: "catalog|dockmerchandiseconfig",
    CONFIG_DOCK_BLOCK: "catalog|dockblockconfig",
    MASTER_APPOINTMENT: "catalog|appointment",
    APPOINTMENT_DASHBOARD: "appointment|dashboard",
    AUTHORIZED_TRANSPORT_UNIT_NUMBER: "management|authorizedtransportunitnumber",
    USER_IMPORT: "catalog|customerimport",
    UPCOMING_APPOINTMENTS_SORT: "requests|upcomingappointmentssort",
    VLCPORT_HISTORIC: "valenciaport|historic",
    VLCPORT_POSITIONING_IMPORT: "valenciaport|positioningsimport",
    VLCPORT_INSPECTION_RESULT: "valenciaport|inspectionresults",
    LEGAL_NOTICE: "contact|legalnotice",
    INVOICING_SUMARY_BY_CONCEPT: "invoicing|summarybyconcept",
    INVOICING_UNINVOICED: "invoicing|uninvoiced",
    ORGANISM_REQUEST_PHARMACY: "requests|pharmacyrequest",
    PARKING_VALIDATION_RULE: "management|parkingvalidationrule",
    IMPORT_CARRIER: "catalog|carrierimport",
    MENU_SETTINGS: "catalog|menusettingsbyrole",
    ORGANISM_CERTIFICATED_TYPE: "catalog|organismcertificatetypeconfig",
    APPOINTMENT_HISTORIC: "appointment|historic",
    APPOINTMENT_IMPORT: "appointment|import",
    QUEUEASSIGNMENT_IMPORT: "queueassignment|import",
    SUGGESTIONS_MAILBOX: "contact|suggestionsmailbox",
    REQUEST_INTEGRATIONS: "requests|integration",
};
