import { useTheme } from "react-jss";
import { NameIcons } from "../../../../assets/icons";
import { CustomIcons } from "../../../common/customIcons/customIcons";
import { ManagementDockDisplayWaitingVehiclesStyle } from "./waitingStyle.jss";

interface IManagenmentDockDisplayWaitingVehiclesProps {
    vehicles: string;
}

export const ManagementDockDisplayWaitingVehicles: React.FC<IManagenmentDockDisplayWaitingVehiclesProps> = ({ vehicles }) => {
    const theme = useTheme();
    const style = ManagementDockDisplayWaitingVehiclesStyle({ theme });

    return (
        <div className={style.containerWaiting}>
            <CustomIcons
                iconName={NameIcons.CLOCK}
                className={style.iconTime}
                margin={"0px"}
                disabledSelect={true}
            />

            <div className={style.vehicles}>{vehicles}</div>
        </div>
    );
};
