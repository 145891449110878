import { AppointmentsAPI } from "../../../../api/appointments";
import { IAppointmentPermission } from "../../../../models/masterAppointment/IAppointmentPermission";
import { throwErrorToastrAsync } from "../../../../utils";
import {
    setLoadingMasterAppointmentPermissionForm,
    setMasterAppointmentPermissionForm,
} from "../../../reducers/masterAppointment/appointmentPermission/form";
import {
    setLoadingMasterAppointmentPermissionList,
    setMasterAppointmentPermissionList,
} from "../../../reducers/masterAppointment/appointmentPermission/list";

import { AppThunk, store } from "../../../store";

const appointmentApi = new AppointmentsAPI();

export const fetchMasterAppointmentPermissionList = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setLoadingMasterAppointmentPermissionList(true));
        const response = await appointmentApi.getMasterAppointmentPermissionList();
        dispatch(setMasterAppointmentPermissionList(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingMasterAppointmentPermissionList(false));
    }
};
export const patchSortAppointmentPermissionsList =
    (tableName: string, id: number, sortPermission: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingMasterAppointmentPermissionList(true));
            await appointmentApi.managementSort(tableName, id, sortPermission);
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(fetchMasterAppointmentPermissionList());
        }
    };

export const fetchMasterAppointmentPermission =
    (id: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingMasterAppointmentPermissionForm(true));
            const response = await appointmentApi.getMasterAppointmentPermissionForm(id);
            let item: IAppointmentPermission = {
                ...response,
            };
            dispatch(setMasterAppointmentPermissionForm(item));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingMasterAppointmentPermissionForm(false));
        }
    };

export const postAndPutMasterAppointmentPermission = (): any => {
    return async (dispatch: any) => {
        try {
            const { form } = store.getState().masterAppointmentPermissionForm;
            dispatch(setLoadingMasterAppointmentPermissionForm(true));

            const newForm = {
                ...form,
            };
            if (form.id) {
                await appointmentApi.putMasterAppointmentPermissionForm(newForm);
            } else {
                await appointmentApi.postMasterAppointmentPermissionForm(newForm);
            }
            dispatch(fetchMasterAppointmentPermissionList());
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            dispatch(setLoadingMasterAppointmentPermissionForm(false));
            return false;
        }
    };
};
export const deleteMasterAppointmentPermission = (): AppThunk => async (dispatch) => {
    try {
        const { id } = store.getState().masterAppointmentPermissionForm.form;

        dispatch(setLoadingMasterAppointmentPermissionForm(true));

        await appointmentApi.removeMasterAppointmentPermissionForm(id ?? 0);
        dispatch(fetchMasterAppointmentPermissionList());
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingMasterAppointmentPermissionForm(false));
    }
};
